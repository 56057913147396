/* eslint-disable import/no-unused-modules */
import { useDispatch, useSelector } from "react-redux";
import { CustomizationProvider, CustomizationProviderProps } from "@twilio-paste/core/customization";
import { CSSProperties, FC, useCallback, useEffect } from "react";

import { RootContainer } from "./RootContainer";
import { AppState, EngagementPhase } from "../store/definitions";
import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { addNotification, changeEngagementPhase } from "../store/actions/genericActions";
import { notifications } from "../notifications";

const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;

export function WebchatWidget() {
    const theme = useSelector((state: AppState) => state.config.theme);
    const dispatch = useDispatch();
    const params = new URL(location.href).searchParams;
    const conversationSid = params.get("conversationSid") || "";

    const initWebchatWithNewSession = useCallback(async () => {
        try {
            if (!conversationSid) throw new Error("No conversationSid provided");

            const data = await sessionDataHandler.fetchAndStoreNewSession({
                conversationSid
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.ErrorPhase }));
        }
    }, [dispatch]);

    useEffect(() => {
        const data = sessionDataHandler.tryResumeExistingSession();
        if (data) {
            try {
                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
            } catch (e) {
                // if initSession fails, go to changeEngagement phase - most likely there's something wrong with the store token or conversation sis
                dispatch(changeEngagementPhase({ phase: EngagementPhase.ErrorPhase }));
            }
        } else {
            // if no token is stored, got engagement form
            initWebchatWithNewSession();
        }
    }, [dispatch, initWebchatWithNewSession]);

    return (
        <AnyCustomizationProvider
            baseTheme={theme?.isLight ? "default" : "dark"}
            theme={theme?.overrides}
            elements={{
                MESSAGE_INPUT: {
                    boxShadow: "none!important" as "none"
                },
                MESSAGE_INPUT_BOX: {
                    boxShadow: "none!important" as "none",
                    display: "inline-block",
                    ":focus": {
                        borderColor: "colorBorder",
                        borderWidth: "borderWidth10",
                        borderStyle: "solid"
                    }
                },
                ALERT: {
                    paddingTop: "space30",
                    paddingBottom: "space30"
                },
                BUTTON: {
                    "&[aria-disabled='true'][color='colorTextLink']": {
                        color: "colorTextLinkWeak"
                    }
                }
            }}
            style={{ height: "100%", width: "100%" }}
        >
            <RootContainer />
        </AnyCustomizationProvider>
    );
}
