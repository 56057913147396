import { Box } from "@twilio-paste/core";
import { Text } from "@twilio-paste/core/text";

export const ErrorPhase = () => {
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}
        >
            <Text as="h1" fontSize="fontSize50">
                Could not load conversation
            </Text>
        </Box>
    );
};
